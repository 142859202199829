/* eslint-disable @typescript-eslint/indent */
import { Card, Dialog, Typography } from '@pwskills/rachnaui';
import React, { useContext } from 'react';
import UserDetailsContext from '@/context/UserDetailsContext';
import Link from 'next/link';
import Saving from './Saving';
import { ModalType } from '../../../interfaces/leadCapture.enum';
import { LeadCaptureFormProps } from '../../../interfaces/leadCapture';

const LeadCaptureForm = (props: LeadCaptureFormProps) => {
  const {
    type,
    saving,
    enquiryType,
    msgLineOne,
    msgLineTwo,
    modalType,
    renderInput,
    renderPhoneNumber,
    renderOtpButton,
    renderEmailField,
    renderOtpField,
    renderBackgroundDropDown,
    renderWorkExperienceDropDown,
    renderSubmitButton,
    renderSelectProgramDropdown,
    count,
    dialogOpen,
    setDialogOpen,
    handleSubmit,
  } = props;

  const { isLoggedIn } = useContext(UserDetailsContext);

  const isOnlineDegree = modalType === ModalType.ONLINE_DEGREE;
  const isCategoryPageForm = modalType === ModalType.CATEGORY_PAGE_FORM;

  return (
    <>
      <Card className="px-[14px] medium:!px-6 large:!px-[46px] py-4 medium:!pt-4 medium:!pb-[34px] large:!py-8 !bg-white">
        <div className="w-full flex flex-col">
          <Typography
            variant="bold"
            component="h4"
            className="text-[#333] font-inter text-left medium:!text-center large:!text-left"
          >
            {isOnlineDegree ? 'Start Your Journey With Us' : 'Start Your Journey Here'}
          </Typography>

          <form
            id={`${enquiryType}_form_input_field`}
            className={`w-full flex flex-col justify-between ${isLoggedIn ? 'gap-12' : 'gap-9'}`}
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <div>
              {renderInput()}

              <div className="flex items-end">
                {renderPhoneNumber()}
                {!isLoggedIn && renderOtpButton()}
              </div>

              {!isLoggedIn && renderOtpField()}

              {isOnlineDegree && renderEmailField()}

              {isCategoryPageForm && renderSelectProgramDropdown()}

              {isOnlineDegree && (
                <>
                  {renderBackgroundDropDown()}
                  {renderWorkExperienceDropDown()}
                </>
              )}
            </div>

            <div className="text-left medium:!text-center large:!text-left">
              <Typography variant="semi-bold" component="body-tiny" className="!text-gray-850">
                By submitting this form you accept and agree to our{' '}
                <Link href="/terms-and-conditions" target="_blank">
                  <span className="semi-bold text-[#E97862]">terms of use</span>
                </Link>
              </Typography>
            </div>

            {renderSubmitButton()}
          </form>
        </div>
      </Card>

      {saving && (
        <Dialog
          open={dialogOpen}
          closeModal={() => {
            setDialogOpen(false);
          }}
        >
          <Saving type={type} msgLineOne={msgLineOne} msgLineTwo={msgLineTwo} count={count} />
        </Dialog>
      )}
    </>
  );
};

export default LeadCaptureForm;
