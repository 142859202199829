import { randomId } from '@pwskills/rachnaui/utils';
import { ICourseEnquiry } from '../interfaces/courseDescription';
import { baseApiUrl } from '../utils/common/env.constants';
import { get, post } from './index';

class CategoryPageApi {
  static async getCategoryData(slug: string) {
    return get(`/course/page/${slug}?isSlug=true&random_id=${randomId()}`);
  }

  static async categoryEnquiry(
    categoryPageId: string,
    token: string,
    data: {
      fullName: string;
      email?: string;
      isdCode: string;
      phoneNumber: string;
      category_name?: string | undefined;
      course_new?: string | undefined;
    }
  ) {
    return post<ICourseEnquiry>(
      `course/sendCategoryPageLeadToLeadSquared/${categoryPageId}`,
      {
        ...data,
        'g-recaptcha-response': token,
      },
      undefined,
      `${baseApiUrl}/v1`
    );
  }
}

export default CategoryPageApi;
