/* eslint-disable array-callback-return */
import useUserCourseDetails from '@/store/userCoursesDetails';
import { useState } from 'react';

export default function useIsCourseBought() {
  const { userCoursesMap } = useUserCourseDetails();
  const [planTypeCourseBought, setPlanTypeCourseBought] = useState({
    slug: '',
    courseId: '',
    purchasedCourseType: '',
  });
  const checkIsCourseBought = (courseId: string) => {
    return Object.hasOwnProperty.call(userCoursesMap, courseId);
    // return userCoursesMap?.hasownproperty(courseId);
  };
  const isCourseBoughtForPlan = (array: any) => {
    const tempArray: boolean[] = [];
    array?.forEach((singleDetails: any) => {
      const check = checkIsCourseBought(singleDetails?._id as string);
      if (check) {
        setPlanTypeCourseBought({
          courseId: singleDetails?._id,
          slug: userCoursesMap[singleDetails?._id]?.courseSlug,
          purchasedCourseType: userCoursesMap[singleDetails?._id]?.purchasedCourseType,
        });
      }

      tempArray.push(check);
    });
    if (tempArray.includes(true)) {
      return true;
    }
    return false;
  };
  const handleMappedCourses = (courseId: string) => {
    if (userCoursesMap[courseId]?.parentCourse)
      return {
        courseId: userCoursesMap[courseId]?.parentCourse?._id,
        courseSlug: userCoursesMap[courseId]?.parentCourse?.slug,
        lessonId: userCoursesMap[courseId]?.recentlyAccesedLesson,
        purchasedCourseSlug: userCoursesMap[courseId]?.courseSlug,
        purchasedCourseType: userCoursesMap[courseId]?.purchasedCourseType,
      };
    return {
      courseId: '',
      courseSlug: '',
    };
  };
  const planCourseMappedDetails = (array: any) => {
    const tempArray: { courseId: string; courseSlug: string; lessonId: string }[] = [];
    array?.forEach((singleDetails: any) => {
      const check: any = handleMappedCourses(singleDetails?._id as string);
      tempArray.push(check);
    });
    let tempObj = { courseId: '', courseSlug: '' };
    tempArray.map((singleObj) => {
      if (singleObj?.courseId !== '') tempObj = { ...singleObj };
    });
    return tempObj;
  };
  const returnLessonId = (courseId: string) => {
    return userCoursesMap[courseId]?.recentlyAccesedLesson;
  };

  return {
    checkIsCourseBought,
    returnLessonId,
    handleMappedCourses,
    isCourseBoughtForPlan,
    planCourseMappedDetails,
    planTypeCourseBought,
  };
}
