import { baseApiUrl } from '@/utils/common/env.constants';
import { ICourseEnquiry } from '../interfaces/courseDescription';
import { get, post } from './index';

class OnlineDegreePageApi {
  static getPartnerInstitutesData() {
    return get('/partnerInstitute/fetch-all');
  }

  static async onlineDegreeEnquiry(
    // categoryPageId: string,
    token: string,
    data: {
      fullName: string;
      email?: string;
      isdCode: string;
      phoneNumber: string;
      field_student_background?: string | undefined;
      field_work_experience?: string | undefined;
      field_course_type?: string | undefined;
    }
  ) {
    return post<ICourseEnquiry>(
      `course/sendOnlineDegreeLeadToLeadSquared`,
      {
        ...data,
        'g-recaptcha-response': token,
      },
      undefined,
      `${baseApiUrl}/v1`
    );
  }
}

export default OnlineDegreePageApi;
