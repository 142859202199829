import { ICourseList, IFilterApiPayload, ISiteMeta } from '@/interfaces/courseListing';
import { randomId } from '@pwskills/rachnaui/utils';
import { post, get } from './index';

class CourseListingApi {
  static async getFilteredCourse(data: IFilterApiPayload, currentPage: number, limit = 6) {
    return post<ICourseList>(`/course/search?page=${currentPage}&limit=${limit}`, data);
  }

  static async getSiteMeta() {
    return get<ISiteMeta>(`/course/sitemeta?platformType=main&random_id=${randomId()}`);
  }
}

export default CourseListingApi;
