/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */

export enum BackgroundFormItem {
  SCHOOL_STUDENT = 'School Student',
  COLLEGE_STUDENT = 'College Student',
  GRADUATE = 'Graduate/Not Working',
  WORKING_PROFESSIONAL = 'Working Professional',
}
export enum WorkExperienceFormItem {
  BEGINNER = '0-2 years',
  EXPERIENCED = '2-4 years',
  ADVANCED = '4-6 years',
  PRO = '6+ years',
}
export enum LeadType {
  DOWNLOAD_CURRICULUM = 'downloadCurriculum',
  DOWNLOAD_PLACEMENT_REPORT = 'download_placement_report',
  CONNECT_WITH_COUNSELLOR = 'ConnectWithCounsellor',
  ALUMNI_CONNECT = 'AlumniConnect',
  DOWNLOAD_BROCHURE = 'download_brochure',
}

export enum LeadCaptureType {
  MODAL = 'modal',
  FORM = 'form',
}

export enum ModalType {
  ONLINE_DEGREE = 'onlineDegree',
  CATEGORY_PAGE_FORM = 'categoryPageForm',
}

export enum LeadCaptureEnquiryType {
  DOWNLOAD_BROCHURE = 'download_brochure',
  APPLY_NOW = 'apply_now',
  COURSE_DESC_TALK_TO_COUNSELLOR = 'course_description_talk_to_counsellor',
  COURSE_LISTING_TALK_TO_COUNSELLOR = 'course_listing_talk_to_counsellor',
  DOWNLOAD_CURRICULUM = 'download_curriculum',
  GET_CALLBACK = 'get_callback',
  PLANS_GET_CALLBACK = 'plans_get_callback',
  LISTING_PAGE_GET_CALLBACK = 'listing_page_get_callback',
  CATEGORY_ENQUIRY = 'category_enquiry',
  JOIN_COMMUNITY = 'join_community',
  DOWNLOAD_PLACEMENT_REPORT = 'download_placement_report',
  HOMEPAGE_TALK_TO_COUNSELLOR = 'homepage_talk_to_counsellor',
  ONLINE_DEGREE_SKILLSHOMEPAGE = 'online_degree_skillshomepage',
  ONLINE_DEGREE_HEROSECTION = 'online_degree_herosection',
  ONLINE_DEGREE_HOMEPAGE = 'online_degree_homepage',
  ONLINE_DEGREE_REGISTER_HERE = 'online_degree_register_here',
  ONLINE_DEGREE_GET_CONNECTED = 'online_degree_get_connected',
  ONLINE_DEGREE_CERTIFICATE_APPLY = 'online_degree_certificateapplynow',
  ONLINE_DEGREE_DOWNLOAD_BROCHURE = 'online_degree_downloadbrochure',
  ONLINE_DEGREE_APPLY_NOW = 'online_degree_applynow',
  ONLINE_DEGREE_GET_COUNSELLING = 'online_degree_getcounselling',
  TALK_TO_OUR_ALUMNI = 'talk_to_our_alumni',
  ONLINE_DEGREE_GET_CALLBACK = 'online_degree_getcallback',
  TALK_TO_OUR_COUNSELLOR = 'talk_to_our_counsellor',
  GET_IN_TOUCH = 'get_in_touch',
}
