import { createContext } from 'react';
import { ICategoryPage } from '@/interfaces/categoryPage';

type CategoryPageContextType = {
  categoryDetails: ICategoryPage | null;
  setCategoryDetails: React.Dispatch<React.SetStateAction<ICategoryPage | null>>;
  loading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const userState = {
  categoryDetails: {
    _id: '',
    testimonial: {
      videoUrl: '',
      altText: '',
      image: '',
      testimonials: [],
    },
    active: false,
    enableCategoryFeature: false,
    enableAboutCategory: false,
    enableAlumni: false,
    enableSuccessStories: false,
    enableCommunity: false,
    enableTestimonials: false,
    enableNews: false,
    enableSeoSection: false,
    enableFaq: false,
    slug: '',
    title: '',
    categoryId: '',
    description: '',
    categoryImage: {
      url: '',
      altText: '',
    },
    categoryMeta: {
      _id: '',
      salaryHike: '',
      highestSalary: '',
      careerTransition: '',
      hiringPartner: '',
      phoneNumber: '',
      weightage: 0,
    },
    seo: {
      _id: '',
      title: '',
      keywords: '',
      description: '',
      ogTitle: '',
      ogDescription: '',
      ogImage: '',
      twitterTitle: '',
      twitterDescription: '',
      twitterImage: '',
    },
    filterTag: [
      {
        type: '',
        tagIds: [''],
        tagValues: [''],
        _id: '',
        name: '',
      },
    ],
    categoryFeature: {
      _id: '',
      description: '',
      thumbnailImage: {
        url: '',
        altText: '',
      },
      videoUrl: '',
      categoryUsp: [],
    },
    about: {
      description: '',
      thumbnailUrl: '',
      altText: '',
      videoUrl: '',
      coursePointers: [],
    },
    alumni: {
      companies: [
        {
          _id: '',
          status: '',
          name: '',
          description: '',
          companyLogoFileName: '',
        },
      ],
      _id: '',
      learnersPlaced: '',
      highestSalary: '',
      industryExpert: '',
      hiringPartners: '',
      report: '',
    },
    community: {
      description: '',
      inviteLink: '',
    },
    news: [
      {
        _id: '',
        title: '',
        url: '',
        newsFrom: {
          _id: '',
          status: '',
          name: '',
          description: '',
          companyLogoFileName: '',
        },
        thumbnail: '',
        altText: '',
        date: '',
      },
    ],
    seoSection: {
      headingOne: '',
      headingTwo: '',
      body: '',
    },
    faq: [{ _id: '', question: '', answer: '', videoLink: '' }],
    mentors: [
      {
        description: '',
        email: '',
        img: { source: '', link: '' },
        instructorDetail: {
          companyExperience: [''],
          workExperience: 0,
          teachingExperience: 0,
        },
        isInstructor: false,
        isSuperAdmin: false,
        name: '',
        social: {
          linkedin: '',
          youtube: '',
          facebook: '',
          twitter: '',
          instagram: '',
        },
        video: { link: '' },
        _id: '',
      },
    ],
    enableMentor: false,
    freeFilterTag: [],
    categoryDetails: {
      _id: '',
      title: '',
      parent: '',
      slug: '',
    },
  },
  setCategoryDetails: () => null,
  loading: false,
  setIsLoading: () => null,
};

const CategoryPageContext = createContext<CategoryPageContextType>(userState);

export default CategoryPageContext;
