import { baseApiUrl, penPencilApiUrl } from '@/utils/common/env.constants';
import { ClientType } from '../interfaces/api.types';
import { post } from './index';
import { IObjDataDb } from '../interfaces/leadCapture';

class LeadCaptureApi {
  static async getOtp<T>(
    data: {
      username: string;
      countryCode: string;
      organizationId: string;
      firstName?: string;
      lastName?: string;
    },
    smsType = '0'
  ) {
    return post<T>(
      `/v3/users/get-otp-register?smsType=${smsType}`,
      data,
      undefined,
      penPencilApiUrl,
      ClientType.PENPENCIL
    );
  }

  static async resendOtp<T>(data: { mobile: string; organizationId: string }, smsType = '0') {
    return post<T>(
      `/v3/users/resend-otp?smsType=${smsType}`,
      data,
      undefined,
      penPencilApiUrl,
      ClientType.PENPENCIL
    );
  }

  static async sendDataToDatabase(data: IObjDataDb) {
    return post('/v2/ga-events', data, undefined, baseApiUrl);
  }

  static async getPPToken<T>(data: {
    username: string;
    otp: string;
    organizationId: string;
    grant_type: string;
    client_id?: string;
    client_secret?: string;
  }) {
    return post<T>('/v3/oauth/token', data, undefined, penPencilApiUrl, ClientType.PENPENCIL);
  }
}
export default LeadCaptureApi;
