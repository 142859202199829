import { createContext } from 'react';
import { ICourseDescription, IMappedCourseDetails } from '@/interfaces/courseDescription';

type CourseContextType = {
  courseDetails: ICourseDescription | null;
  setCourseDetails: React.Dispatch<React.SetStateAction<ICourseDescription | null>>;
  isCourseBought: boolean;
  lessonId: string;
  loading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mappedCourseDetails: IMappedCourseDetails;
};

const userState = {
  courseDetails: {
    _id: '',
    isJobGuaranteeProgram: false,
    isJobAssistanceProgram: false,
    hashTag: '',
    pageTheme: '',
    emi: {
      isNoCostEmi: false,
      description: '',
      videoLink: '',
    },
    levels: [''],
    title: '',
    slug: '',
    ratingAverage: 0,
    ratingCount: 0,
    offerDescription: '',
    classTimings: {
      startDate: '',
      endDate: '',
      timings: '',
      doubtClearing: '',
      batchName: '',
    },
    keyValueMetaInfo: { '': '' },
    seo: { title: '', description: '', keywords: '' },
    faq: [{ _id: '', question: '', answer: '', videoLink: '' }],
    description: '',
    img: '',
    videoURL: '',
    pricing: {
      IN: 0,
      US: 0,
      discount: 0,
      isFree: false,
    },
    courseMetaDetails: {
      duration: '',
      instructors: [
        {
          _id: '',
          name: '',
          description: '',
          isInstructor: false,
          social: {
            linkedin: '',
            youtube: '',
          },
          img: {
            link: '',
          },
          video: {
            link: '',
          },
          instructorDetails: {
            companies: [{ designation: '', company: '' }],
            designation: '',
            workExperience: '',
            teachingExperience: '',
          },
        },
      ],
      curriculumPdf: '',
      mentorsCompanies: [
        { _id: '', status: '', name: '', description: '', companyLogoFileName: '' },
      ],
      placementCompanies: [
        { _id: '', status: '', name: '', description: '', companyLogoFileName: '' },
      ],
      projectGallery: {
        data: [
          {
            _id: '',
            title: '',
            img: '',
            desc: '',
          },
        ],
        layoutType: '',
      },
      testimonials: [
        {
          _id: '',
          videoLink: '',
          testimonialType: '',
          name: '',
          designation: '',
          description: '',
          testimonialImage: '',
        },
      ],
      curriculum: [{ _id: '', parent: '', title: '', preview: { url: '', provider: '' } }],
      statsInfo: {
        courseSatisfaction: '',
        careerTransitions: '',
        highestSalary: '',
        averageSalaryHike: '',
        hiringPartner: '',
      },
      certificateType: [{ points: [''], name: '', description: '', certificateImage: '' }],
      courseOffering: [{ icon: { source: '', link: '' }, name: '' }],
      masterTools: [{ source: '', _id: '', link: '' }],
      overview: {
        language: '',
      },
      alumniTestimonial: {
        type: 'TILE',
        videoUrl: '',
        altText: '',
        text: '',
        thumbnail: '',
      },
      successStories: [
        {
          _id: '',
          subName: '',
          achievementStats: '',
          studentName: '',
          studentDesignation: '',
          studentImage: '',
          fromCompanyId: '',
          toCompanyId: '',
          comment: '',
          isFresher: true,
          fromCompanyDetails: [
            {
              _id: '',
              status: '',
              name: '',
              description: '',
              companyLogoFileName: '',
            },
          ],
          toCompanyDetails: [],
        },
      ],
      enableCourseOffering: false,
      enableMasterTools: false,
      enableExpertCompany: false,
      enableCurriculum: false,
      enableProjectGallery: false,
      enableCertificate: false,
      enableAlumniCompany: false,
      enablePlacementStats: false,
      enableAlumniTestimonials: false,
      enableCertificateCriteria: false,
      enableCourseInstructor: false,
      enableSEO: false,
      enableSuccessStories: false,
      counsellingStripOne: false,
      counsellingStripTwo: false,
    },
    courseType: '',
    coursePurchaseType: '',
    categoryDetails: { _id: '', title: '', parent: '', slug: '' },
    categoryId: '',
    outcomes: { roleAfterCompletion: '', learningMode: '', skillsGain: '', minimumEligibility: '' },
    sections: [
      {
        lessons: [''],
      },
    ],
    about: {
      description: '',
      thumbnailUrl: '',
      altText: '',
      coursePointers: [
        {
          _id: '',
          title: '',
          subText: '',
        },
      ],
    },
    enableAboutCourse: false,
    mappedCourseDetails: {
      courseId: '',
      courseSlug: '',
    },
    hashtagDetails: [
      {
        slug: '',
        value: '',
      },
    ],
    parentCategoryDetails: {
      _id: '',
      title: '',
      slug: '',
    },
  },
  setCourseDetails: () => null,
  isCourseBought: false,
  lessonId: '',
  loading: false,
  setIsLoading: () => null,
  mappedCourseDetails: {
    courseId: '',
    courseSlug: '',
  },
};

const CourseDescriptionContext = createContext<CourseContextType>(userState);

export default CourseDescriptionContext;
