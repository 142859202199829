/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-undef */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import { Button, Dialog, Input, MobileInput, Typography } from '@pwskills/rachnaui';
import { useUTMParameters } from '@pwskills/rachnaui/hooks';
import { randomId } from '@pwskills/rachnaui/utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import downArrow from '@/assets/icons/caret-down.svg';
import { useRouter } from 'next/router';
import useIsCourseBought from '@/hooks/useIsCourseBought';
import LeadCaptureForm from './LeadCaptureForm';
import LeadCaptureModal from './LeadCaptureModal';
import Saving from './Saving';
import { isEmail, isName, isValidIndianPhoneNumber } from '../../../utils/common/common.validation';
import {
  penPencilClientId,
  penPencilTokenClientId,
  penPencilTokenClientSecret,
  penPencilTokenGrantType,
  s3Url,
} from '../../../utils/common/env.constants';
import moEvent from '../../../utils/moEngage';
import LeadCaptureApi from '../../../api/leadCapture';
import CategoryPageApi from '../../../api/categoryPage';
import CourseDescription from '../../../api/courseDescription';
import OnlineDegreePageApi from '../../../api/onlineDegree';
import UserDetailsContext from '../../../context/UserDetailsContext';
import CourseDescriptionContext from '../../../context/CourseDescriptionContext';
import {
  BackgroundFormItem,
  LeadCaptureType,
  LeadType,
  ModalType,
  WorkExperienceFormItem,
} from '../../../interfaces/leadCapture.enum';

import { IFilterApiPayload, IPayloadFilter } from '../../../interfaces/courseListing';
import CourseListingApi from '../../../api/courseListing';
import { IError } from '../../../interfaces/common';
import CategoryPageContext from '../../../context/CategoryPageContext';
import { IObjDataDb, LeadCaptureProps } from '../../../interfaces/leadCapture';
import { GAevent } from '../../../utils/firebase.utils';

type ContactDetails = {
  fullName: string;
  email?: string;
  isdCode: string;
  phoneNumber: string;
  source?: string;
  medium?: string;
  campaign?: string;
  category_name?: string;
};

type Error = { nameError: boolean; emailError: boolean; phoneNumberError: boolean };

const AUTO_CLOSE_TIME = 5000;

const LeadCapture = ({
  leadCaptureType,
  modelOpen,
  setModelOpen,
  buttonText,
  modalType,
  type,
  redirectLink,
  courseId,
  categoryId,
  courseName,
  collegeName,
  categoryName,
  enquiryType,
  enquiryTypeNPF,
  img,
  msgLineOne,
  msgLineTwo,
  curriculumPdf,
  reportLink,
  pageName,
}: LeadCaptureProps) => {
  const { user, isLoggedIn } = useContext(UserDetailsContext);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean | null>(null);
  const [isValidName, setIsValidName] = useState(false);
  const [otp, setOtp] = useState('');
  const [dropDownBackground, setDropDownBackground] = useState(false);
  const [dropDownWorkExperience, setDropDownWorkExperience] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(
    !!(isLoggedIn && user.phoneNumber)
  );
  const [selectedBackground, setSelectedBackground] = useState('');
  const [selectedWorkExperience, setSelectedWorkExperience] = useState('');
  const [count, setCount] = useState(AUTO_CLOSE_TIME / 1000);
  const [currentTimerCount, setCurrentTimerCount] = useState(0);
  const utmQuery = useUTMParameters();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState('');
  const [selectedDropdownCourseId, setSelectedDropdownCourseId] = useState('');
  const [dropdownOptions, setDropDownOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const router = useRouter();
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const downloadDirectly =
    isLoggedIn &&
    modalType !== ModalType.ONLINE_DEGREE &&
    leadCaptureType === LeadCaptureType.MODAL;
  const { checkIsCourseBought } = useIsCourseBought();
  const [isError, setIsError] = useState<Error>({
    emailError: false,
    nameError: false,
    phoneNumberError: false,
  });
  const { categorySlug } = router.query;
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLAnchorElement | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const { courseDetails } = useContext(CourseDescriptionContext);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [contactDetails, setContactDetails] = useState<ContactDetails>({
    fullName: isLoggedIn ? `${user.firstName} ${user.lastName}` : '',
    email: isLoggedIn ? user.email : '',
    isdCode: (isLoggedIn && user.isdCode) || '',
    phoneNumber: isLoggedIn ? user.phoneNumber : '',
  });

  const [isCourseBought, setIsCourseBought] = useState<boolean>(false);
  const { categoryDetails } = useContext(CategoryPageContext);

  const INITIAL_TIMER_COUNT = 15;
  const SMS_TYPE = '1';

  const dropdownBackgroundOptions = [
    { title: BackgroundFormItem.SCHOOL_STUDENT },
    { title: BackgroundFormItem.COLLEGE_STUDENT },
    { title: BackgroundFormItem.GRADUATE },
    { title: BackgroundFormItem.WORKING_PROFESSIONAL },
  ];

  const dropDownWorkExOptions = [
    { title: WorkExperienceFormItem.BEGINNER },
    { title: WorkExperienceFormItem.EXPERIENCED },
    { title: WorkExperienceFormItem.ADVANCED },
    { title: WorkExperienceFormItem.PRO },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (value.length > 0 && value.trim() !== '') {
      setIsValidName(true);
    } else {
      setIsValidName(false);
    }

    setContactDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const payloadLabel: Record<string, keyof IPayloadFilter> = {
    program_type: 'programType',
    level_type: 'level',
    domain: 'domains',
    category: 'categories',
    instructor_id: 'instructors',
    language_type: 'languages',
    sub_category: 'subcategories',
    subCategory: 'subcategories',
    pricing_type: 'isFree',
    search: 'text',
    tag: 'hashTag',
    categorySlug: 'filterConfigSlug',
  };

  const generatePayloadFromQuery = () => {
    const payload = {
      level: '',
      programType: '',
      text: '',
      instructors: [],
      categories: [],
      languages: [],
      subcategories: [],
      domains: [],
      isFree: '',
      hashTag: [],
      filterConfigSlug: '',
    };
    const queryObject = router.query;

    Object.keys(queryObject).forEach((key) => {
      if (key === 'page') return;
      const payloadKey = payloadLabel[key];
      const queryObjectValue = queryObject[key];
      if (Array.isArray(payload[payloadKey])) {
        if (Array.isArray(queryObjectValue)) {
          (payload[payloadKey] as string[]) = queryObjectValue;
        } else if (queryObjectValue) {
          (payload[payloadKey] as string[]).push(queryObjectValue);
        }
      } else if (payloadKey === 'text') {
        payload[payloadKey] = queryObjectValue as string;
      } else (payload[payloadKey] as string) = queryObjectValue as string;
    });
    return payload;
  };

  // const getIsCourseBought = async () => {
  //   try {
  //     const courseBoughtResponse = await CourseDescription.isCourseBought(courseDetails?._id || '');
  //     if (courseBoughtResponse?.data) {
  //       setIsCourseBought(true);
  //     }
  //   } catch (error: unknown) {
  //     setIsCourseBought(false);
  //     // eslint-disable-next-line no-console
  //     // console.error((error as IError)?.error);
  //   }
  // };

  const handleGetOtp = async () => {
    const userData = {
      username: contactDetails.phoneNumber,
      countryCode: contactDetails.isdCode,
      organizationId: `${penPencilClientId}`,
      firstName: contactDetails.fullName,
      lastName: '',
    };
    try {
      const smsType = contactDetails.isdCode !== '+91' ? SMS_TYPE : '0';
      setCurrentTimerCount(INITIAL_TIMER_COUNT);
      const response = await LeadCaptureApi.getOtp(userData, smsType);
      if (response) {
        setIsOtpSent(true);
        setOtp('');
      }
    } catch (error: any) {
      // console.log(err)
      if (error?.response?.status === 400) {
        setIsOtpSent(true);
        setOtp('');
      }
    }
  };

  const renderInput = () => {
    return (
      <Input
        id={leadCaptureType === LeadCaptureType.MODAL ? 'name' : 'name_input_field'}
        wrapperClass="mt-5"
        label={leadCaptureType === LeadCaptureType.MODAL ? 'Enter Your Full Name' : 'Name'}
        name="fullName"
        value={contactDetails.fullName}
        onChange={(nameData) => {
          handleChange(nameData);
          setIsError({ ...isError, nameError: false });
        }}
        error={isError.nameError}
        helperText={(isError.nameError && isName(contactDetails.fullName)) || ''}
        isRequired
        disabled={isLoggedIn}
      />
    );
  };

  const renderPhoneNumber = () => {
    return (
      <div className="flex items-end !w-full">
        <div
          className="!w-full"
          id={leadCaptureType === LeadCaptureType.MODAL ? 'phoneNumber' : 'phoneNumber_input_field'}
        >
          <MobileInput
            // id="get_in_touch_phoneNumber"
            autoFocus={false}
            disabled={isLoggedIn}
            defaultCountry="IN"
            wrapperClass="mt-5 flex !flex-grow !w-full [&>div]:!w-full"
            className="w-full"
            countrySearch
            error={isError.phoneNumberError}
            helperText={
              (isError.phoneNumberError &&
                isValidIndianPhoneNumber(contactDetails.phoneNumber, contactDetails.isdCode)) ||
              ''
            }
            onChange={(e) => {
              setContactDetails({ ...contactDetails, phoneNumber: e });
              setIsError({ ...isError, phoneNumberError: false });
            }}
            isValid={(val) => {
              setIsValidPhoneNumber(val);
              if (val && isOtpSent) {
                setIsOtpSent(false);
                setIsOtpVerified(false);
              }
            }}
            value={contactDetails.phoneNumber}
            countryCode={(val) => setContactDetails({ ...contactDetails, isdCode: val })}
          />
        </div>
      </div>
    );
  };

  const renderOtpButton = () => {
    return (
      <button
        type="button"
        disabled={!isValidPhoneNumber || isOtpSent || !isValidName}
        onClick={() => {
          handleGetOtp();
        }}
        className={`w-fit text-base font-semibold whitespace-nowrap border-b border-stroke-300 ${
          isLoggedIn || !isValidPhoneNumber || isOtpSent || !isValidName
            ? '!text-orange-100'
            : '!text-orange-500'
        } p-1 `}
      >
        {isOtpSent ? 'OTP Sent' : 'GET OTP'}
      </button>
    );
  };

  const handleResendOtp = async () => {
    setIsOtpVerified(null);
    const dataToBeSend = {
      mobile: contactDetails.phoneNumber,
      organizationId: `${penPencilClientId}`,
    };
    try {
      const smsType = contactDetails.isdCode !== '+91' ? SMS_TYPE : '0';
      setCurrentTimerCount(INITIAL_TIMER_COUNT);
      const response = await LeadCaptureApi.resendOtp(dataToBeSend, smsType);
      if (response) {
        setIsOtpSent(true);
        setOtp('');
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setIsOtpSent(true);
        setOtp('');
      }
    }
  };

  const getFilteredCourseData = async (pageNumber: number) => {
    setIsLoading(true);

    const filterSearchPayload = generatePayloadFromQuery();
    setPageNo(pageNumber);
    // const pageNumber = Number.isNaN(router.query.page) ? -1 : router.query.page;

    try {
      const payload = {
        ...filterSearchPayload,
        filterConfigSlug: categorySlug,
        filterConfigSrc: 'category_page',
      };

      const response = await CourseListingApi.getFilteredCourse(
        payload as IFilterApiPayload,
        Number(pageNumber)
      );

      if (response) {
        const responseData = response?.data;
        setTotalPages(responseData?.meta[0]?.metadata[0]?.totalPages);
        if (responseData.courses.length > 0) {
          // setCoursesDetails(responseData?.courses);
          setDropDownOptions((prevOptions) => {
            const newOptions = responseData.courses.map((course) => course);
            return [...prevOptions, ...newOptions];
          });
        }
      }
      setIsLoading(false);
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error((error as IError)?.error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const isResendDisabled = useMemo(() => {
    return currentTimerCount > 0;
  }, [currentTimerCount]);

  const handleDropDownOpen = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const handleBackgroundDropDownOpen = () => {
    setDropDownBackground(!dropDownBackground);
  };

  const handleWorkExperienceDropDownOpen = () => {
    setDropDownWorkExperience(!dropDownWorkExperience);
  };

  const handleSelectedDropdownOption = (option: string) => {
    setSelectedDropdownOption(option);
    handleDropDownOpen();
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    const { currentTarget } = e;
    requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        if (dropDownOpen) handleDropDownOpen();
        if (dropDownBackground) handleBackgroundDropDownOpen();
        if (dropDownWorkExperience) handleWorkExperienceDropDownOpen();
        // if (showDeleteModal) setShowDeleteModal(false);
      }
    });
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    // Allow only digits
    if (/^\d*$/.test(input)) {
      // Allow only up to 4 digits
      if (input.length <= 4) {
        setOtp(input);
      }
    }
  };

  const renderOtpField = () => {
    return (
      <div className="flex flex-col gap-1">
        <div className="flex items-end">
          <Input
            id={leadCaptureType === LeadCaptureType.MODAL ? 'otp' : 'otp_input_field'}
            type="text"
            value={otp}
            disabled={!isValidPhoneNumber || !isOtpSent}
            onChange={(e) => {
              handleOtpChange(e);
            }}
            className="!w-full"
            wrapperClass="mt-5 flex !flex-grow !w-full [&>div]:!w-full"
            label="OTP"
          />

          {isOtpSent &&
            (isResendDisabled ? (
              <Typography
                variant="bold"
                component="body-tiny"
                className="text-orange-500 w-fit font-semibold whitespace-nowrap border-b border-stroke-300"
              >
                00:
                {currentTimerCount.toLocaleString('en-IN', {
                  minimumIntegerDigits: 2,
                })}
              </Typography>
            ) : (
              <button
                type="button"
                disabled={!isValidPhoneNumber && isOtpSent}
                onClick={() => {
                  // handleGetOtp();
                  handleResendOtp();
                }}
                className={`w-fit font-bold whitespace-nowrap border-b border-stroke-300 ${
                  currentTimerCount !== 0 && (!isValidPhoneNumber || isOtpSent)
                    ? '!text-orange-100'
                    : '!text-orange-500'
                } p-1 text-[12px]`}
              >
                Resend OTP
              </button>
            ))}
        </div>
        {isOtpSent && !isOtpVerified && isOtpVerified !== null && (
          <Typography variant="semi-bold" component="body-tiny" className="!text-red-500">
            *Invalid OTP{' '}
          </Typography>
        )}
      </div>
    );
  };

  const renderEmailField = () => {
    return (
      <Input
        id={leadCaptureType === LeadCaptureType.MODAL ? 'email' : 'email_input_field'}
        wrapperClass="mt-5"
        label="Email"
        name="email"
        value={contactDetails.email}
        error={isError.emailError}
        helperText={
          (isError.emailError && isEmail((contactDetails.email && contactDetails?.email) || '')) ||
          ''
        }
        onChange={(emailData) => {
          handleChange(emailData);
          setIsError({ ...isError, emailError: false });
        }}
        isRequired
        disabled={isLoggedIn && user.email !== undefined}
      />
    );
  };

  const handleSelectedBackground = (option: string) => {
    setSelectedBackground(option);
    if (
      option === BackgroundFormItem.SCHOOL_STUDENT ||
      option === BackgroundFormItem.COLLEGE_STUDENT ||
      option === BackgroundFormItem.GRADUATE
    ) {
      setSelectedWorkExperience(''); // Clear work experience if the selected background is one of the specified options
    }

    handleBackgroundDropDownOpen();
  };

  const handleSelectedWorkExperience = (option: string) => {
    setSelectedWorkExperience(option);
    handleWorkExperienceDropDownOpen();
  };

  const renderBackgroundDropDown = () => {
    return (
      <div className="relative mt-5" onBlur={handleBlur}>
        <button
          type="button"
          onClick={handleBackgroundDropDownOpen}
          className="border-b-[1px] border-b-solid border-stroke-300  text-left !w-full"
        >
          <div className="py-2 inline-flex flex-row gap-x-1 !rounded !w-full !justify-between">
            <input
              id={
                leadCaptureType === LeadCaptureType.MODAL
                  ? 'background_dropdown'
                  : 'background_dropdown_form'
              }
              type="text"
              value={selectedBackground}
              className="!hidden"
            />
            <Typography
              variant="regular"
              component="body-regular"
              className={`${
                selectedBackground.length === 0 ? 'text-[#757575] ' : ''
              } font-sans-serif`}
            >
              {selectedBackground.length === 0 ? 'Select Background' : selectedBackground}
            </Typography>
            <Image src={downArrow} alt="downArrow" width={12} height={12} />
          </div>
        </button>
        {dropDownBackground && (
          <div className="flex flex-col absolute top-[42px] left-0 z-10 shadow-[0px_8px_24px_0px_rgba(0,0,0,0.06)] !w-full max-h-[180px] overflow-y-auto">
            {dropdownBackgroundOptions?.map((dropdown, index) => {
              return (
                <button
                  key={randomId()}
                  type="button"
                  onClick={() => {
                    // setSelectedDropdownCourseId(dropdown._id);
                    handleSelectedBackground(dropdown.title);
                  }}
                >
                  <div
                    onBlur={handleBlur}
                    className={`flex flex-row  px-4 py-2 gap-x-2 bg-[#f8f8f8] ${
                      dropdownBackgroundOptions.length - 1 === index ? 'rounded-b' : 'rounded-t'
                    } ${
                      index === 0
                        ? 'border-t-[1px] border-x-[1px] border-solid border-x-[#EFEFEF] border-t-[#EFEFEF]  !rounded-t'
                        : index === dropdownBackgroundOptions.length - 1
                        ? 'border-y-[1px] border-x-[1px] border-solid border-x-[#EFEFEF] border-y-[#EFEFEF] !rounded-b'
                        : 'border-t-[1px] border-x-[1px] border-t-solid border-x-solid border-x-[#EFEFEF] border-t-[#EFEFEF] '
                    } hover:bg-[#F8F8F8]`}
                  >
                    <Typography
                      variant="regular"
                      component="body-small"
                      className=" text-[14px] leading-[22px] text-[#2F2F2F] w-[158px] self-center !text-start"
                    >
                      {dropdown.title}
                    </Typography>
                  </div>
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderWorkExperienceDropDown = () => {
    return (
      <div className="relative mt-5" onBlur={handleBlur}>
        <button
          type="button"
          onClick={handleWorkExperienceDropDownOpen}
          className="border-b-[1px] border-b-solid border-stroke-300  text-left !w-full"
          disabled={
            selectedBackground.includes(BackgroundFormItem.SCHOOL_STUDENT) ||
            selectedBackground.includes(BackgroundFormItem.COLLEGE_STUDENT) ||
            selectedBackground.includes(BackgroundFormItem.GRADUATE)
          }
        >
          <div className="py-2 inline-flex flex-row gap-x-1 !rounded !w-full !justify-between">
            <input
              id={
                leadCaptureType === LeadCaptureType.MODAL
                  ? 'work_experience_dropdown'
                  : 'work_experience_dropdown_form'
              }
              type="text"
              value={selectedWorkExperience}
              className="!hidden"
            />
            <Typography
              variant="regular"
              component="body-regular"
              className={`${
                selectedWorkExperience.length === 0 ? 'text-[#757575] ' : ''
              } font-sans-serif ${
                selectedBackground.includes(BackgroundFormItem.SCHOOL_STUDENT) ||
                selectedBackground.includes(BackgroundFormItem.COLLEGE_STUDENT) ||
                selectedBackground.includes(BackgroundFormItem.GRADUATE)
                  ? 'text-gray-400'
                  : ''
              }`}
            >
              {selectedWorkExperience.length === 0 ? 'Work Experience' : selectedWorkExperience}
            </Typography>
            <Image src={downArrow} alt="downArrow" width={12} height={12} />
          </div>
        </button>
        {dropDownWorkExperience && (
          <div className="flex flex-col absolute top-[42px] left-0 z-10 shadow-[0px_8px_24px_0px_rgba(0,0,0,0.06)] !w-full max-h-[180px] overflow-y-auto">
            {dropDownWorkExOptions?.map((dropdown, index) => {
              return (
                <button
                  key={randomId()}
                  type="button"
                  onClick={() => {
                    // setSelectedDropdownCourseId(dropdown._id);
                    handleSelectedWorkExperience(dropdown.title);
                  }}
                >
                  <div
                    onBlur={handleBlur}
                    className={`flex flex-row  px-4 py-2 gap-x-2 bg-[#f8f8f8] ${
                      dropDownWorkExOptions.length - 1 === index ? 'rounded-b' : 'rounded-t'
                    } ${
                      index === 0
                        ? 'border-t-[1px] border-x-[1px] border-solid border-x-[#EFEFEF] border-t-[#EFEFEF]  !rounded-t'
                        : index === dropDownWorkExOptions.length - 1
                        ? 'border-y-[1px] border-x-[1px] border-solid border-x-[#EFEFEF] border-y-[#EFEFEF] !rounded-b'
                        : 'border-t-[1px] border-x-[1px] border-t-solid border-x-solid border-x-[#EFEFEF] border-t-[#EFEFEF] '
                    } hover:bg-[#F8F8F8]`}
                  >
                    <Typography
                      variant="regular"
                      component="body-small"
                      className=" text-[14px] leading-[22px] text-[#2F2F2F] w-[158px] self-center !text-start"
                    >
                      {dropdown.title}
                    </Typography>
                  </div>
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderSelectProgramDropdown = () => {
    return (
      <div className="relative mt-5" onBlur={handleBlur}>
        <button
          type="button"
          onClick={handleDropDownOpen}
          className="border-b-[1px] border-b-solid border-stroke-300  text-left !w-full"
        >
          <div className="py-2 inline-flex flex-row gap-x-1 !rounded !w-full !justify-between">
            <Typography
              variant="regular"
              component="body-regular"
              className={`${
                selectedDropdownOption.length === 0 ? 'text-[#757575] ' : ''
              } font-sans-serif`}
            >
              {selectedDropdownOption.length === 0 ? 'Select Program' : selectedDropdownOption}
            </Typography>
            <Image src={downArrow} alt="downArrow" width={12} height={12} />
          </div>
        </button>
        {dropDownOpen && (
          <div
            className="flex flex-col absolute top-[42px] left-0 z-10 shadow-[0px_8px_24px_0px_rgba(0,0,0,0.06)] !w-full max-h-[180px] overflow-y-auto"
            onScroll={(event) => {
              const target = event.target as HTMLElement;
              const scrollPercentage =
                (target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100;

              if (scrollPercentage >= 50 && !isLoading && pageNo < totalPages) {
                getFilteredCourseData(pageNo + 1);
              }
            }}
          >
            {dropdownOptions?.map((dropdown, index) => {
              return (
                <button
                  key={randomId()}
                  type="button"
                  onClick={() => {
                    setSelectedDropdownCourseId(dropdown._id);
                    handleSelectedDropdownOption(dropdown.title);
                  }}
                >
                  <div
                    onBlur={handleBlur}
                    className={`flex flex-row  px-4 py-2 gap-x-2 bg-[#f8f8f8] ${
                      dropdownOptions.length - 1 === index ? 'rounded-b' : 'rounded-t'
                    } ${
                      index === 0
                        ? 'border-t-[1px] border-x-[1px] border-solid border-x-[#EFEFEF] border-t-[#EFEFEF]  !rounded-t'
                        : index === dropdownOptions.length - 1
                        ? 'border-y-[1px] border-x-[1px] border-solid border-x-[#EFEFEF] border-y-[#EFEFEF] !rounded-b'
                        : 'border-t-[1px] border-x-[1px] border-t-solid border-x-solid border-x-[#EFEFEF] border-t-[#EFEFEF] '
                    } hover:bg-[#F8F8F8]`}
                  >
                    <Typography
                      variant="regular"
                      component="body-small"
                      className=" text-[14px] leading-[22px] text-[#2F2F2F] w-[158px] self-center !text-start"
                    >
                      {dropdown.title}
                    </Typography>
                  </div>
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  function areContactDetailsValid() {
    return (
      contactDetails.fullName.trim().length >= 2 &&
      contactDetails.isdCode &&
      contactDetails.phoneNumber &&
      isValidPhoneNumber
    );
  }

  const disabled = useMemo(() => {
    const isBackgroundWithoutWorkEx =
      selectedBackground.includes(BackgroundFormItem.SCHOOL_STUDENT) ||
      selectedBackground.includes(BackgroundFormItem.COLLEGE_STUDENT) ||
      selectedBackground.includes(BackgroundFormItem.GRADUATE);

    const additionalConditions =
      modalType === ModalType.ONLINE_DEGREE
        ? isBackgroundWithoutWorkEx
          ? selectedBackground && contactDetails.email
          : selectedBackground && selectedWorkExperience && contactDetails.email
        : modalType === ModalType.CATEGORY_PAGE_FORM
        ? selectedDropdownOption
        : true;

    const baseConditions = areContactDetailsValid() && additionalConditions;

    if (isLoggedIn) {
      return !baseConditions;
    }
    return !(baseConditions && otp);
  }, [
    contactDetails.fullName,
    contactDetails.isdCode,
    contactDetails.phoneNumber,
    isValidPhoneNumber,
    isLoggedIn,
    otp,
    selectedBackground,
    selectedDropdownOption,
    selectedWorkExperience,
    contactDetails.email,
  ]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return '';
    }

    try {
      const token = await executeRecaptcha(randomId());
      return token;
    } catch (error) {
      return '';
    }
  }, [executeRecaptcha]);

  const closeModal = () => {
    setSaving(false);
    if (setModelOpen) {
      setModelOpen(false);
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (isLoggedIn) {
      setContactDetails({
        ...contactDetails,
      });
      setSelectedDropdownOption('');
      setSelectedBackground('');
      setSelectedWorkExperience('');
      setCount(AUTO_CLOSE_TIME / 1000);
    } else {
      setContactDetails({
        ...contactDetails,
        fullName: '',
        email: '',
        isdCode: '+91',
        phoneNumber: '',
      });
    }
    setIsError({ nameError: false, emailError: false, phoneNumberError: false });
  };

  function getEventDataObj(data: ContactDetails, eventType: string) {
    const commonData = {
      user_id: user?._id || '',
      user_type: isLoggedIn ? 'loggedin' : 'not_loggedin',
      course_status: isLoggedIn && isCourseBought ? 'purchased' : 'not_purchased',
      enquiry_type: enquiryType,
      ...(eventType === 'MO' && {
        phone_number: data.phoneNumber || '',
        username: `${user?.firstName} ${user?.lastName}` || '',
        ...(user?.verified && user?.email && { email: user.email }),
      }),
      ...(modalType === ModalType.ONLINE_DEGREE && {
        student_background: selectedBackground,
        work_experience: selectedWorkExperience,
        courseType: 'online degree',
      }),
      ...(eventType === 'GA' && {
        page_name: pageName,
        form_id:
          leadCaptureType === LeadCaptureType.FORM
            ? `${enquiryType}_form_input_field`
            : `${enquiryType}_form`,
      }),
    };

    if (leadCaptureType === LeadCaptureType.MODAL) {
      return {
        ...commonData,
        course_name: courseName || (courseDetails && courseDetails?.title),
        course_id: courseId || (courseDetails && courseDetails._id),
        category_name: categoryName || (courseDetails && courseDetails?.categoryDetails?.title),
        category_id: categoryId || (courseDetails && courseDetails?.categoryDetails?._id),
      };
    }
    return {
      ...commonData,
      course_name: selectedDropdownOption,
      course_id: selectedDropdownCourseId,
      category_name: categoryDetails?.title,
      category_id: categoryDetails?._id,
    };
  }

  function getUserPseudoId() {
    const gaCookie = document.cookie.split('; ').find((row) => row.startsWith('_ga='));

    if (gaCookie) {
      const gaParts = gaCookie.split('.');
      if (gaParts.length > 2) {
        return `${gaParts[2]}.${gaParts[3]}`;
      }
    }

    return null;
  }

  function getObjDataDb(data: ContactDetails): IObjDataDb {
    const dbData: IObjDataDb = {
      ...(isLoggedIn && { user_id: user?._id }),
      user_type: isLoggedIn ? 'logged_in' : 'logged_out',
      phone_number: data.phoneNumber || '',
      username: `${user?.firstName} ${user?.lastName}` || '',
      ...(user?.verified && user?.email && { email: user.email }),
      ...(modalType === ModalType.ONLINE_DEGREE && {
        background: selectedBackground,
        experience: selectedWorkExperience,
      }),
      page_name: pageName,
      ...(!isLoggedIn && { user_pseudo_id: getUserPseudoId() || undefined }),
      date_time: new Date().toISOString(),
    };

    if (leadCaptureType === LeadCaptureType.MODAL) {
      return {
        ...dbData,
        course_name: courseName || (courseDetails && courseDetails?.title),
        course_id: courseId || (courseDetails && courseDetails._id),
        category_name: categoryName || (courseDetails && courseDetails?.categoryDetails?.title),
        category_id: categoryId || (courseDetails && courseDetails?.categoryDetails?._id),
      };
    }
    return {
      ...dbData,
      course_name: selectedDropdownOption,
      course_id: selectedDropdownCourseId,
      category_name: categoryDetails?.title,
      category_id: categoryDetails?._id,
    };
  }

  const sendDataToDB = async (data: ContactDetails) => {
    const objData = getObjDataDb(data);
    try {
      await LeadCaptureApi.sendDataToDatabase(objData);
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error((error as IError)?.error);
    }
  };

  const sendLead = async (data: ContactDetails) => {
    const token = await handleReCaptchaVerify();
    const GAEventDataObj = getEventDataObj(data, 'GA');
    GAevent('sk_form_submit', GAEventDataObj);
    sendDataToDB(data);

    try {
      if (isLoggedIn) {
        const moEventData = getEventDataObj(data, 'MO');

        if (modalType === ModalType.ONLINE_DEGREE) {
          moEvent('online_degree_course_enquiry', moEventData);
        } else {
          moEvent('course_enquiry_request', moEventData);
        }
      }

      if (modalType === ModalType.CATEGORY_PAGE_FORM) {
        await CategoryPageApi.categoryEnquiry(categoryDetails?._id as string, token, data);
      } else if (categoryId) {
        await CategoryPageApi.categoryEnquiry(categoryId as string, token, data);
      } else if (courseId && modalType !== ModalType.ONLINE_DEGREE) {
        await CourseDescription.courseEnquiry(courseId as string, token, data);
      } else {
        await OnlineDegreePageApi.onlineDegreeEnquiry(token, data);
      }

      // handleDownload();
    } catch (error: unknown) {
      // setApiErrorMessage((error as IError)?.error || 'Something went wrong!');
      // setApiError(true);
    } finally {
      // handleDownload();
    }
  };

  const handleDownload = async () => {
    const dataToSend = {
      username: contactDetails.phoneNumber,
      otp,
      organizationId: `${penPencilClientId}`,
      grant_type: `${penPencilTokenGrantType}`,
      client_id: `${penPencilTokenClientId}`,
      client_secret: `${penPencilTokenClientSecret}`,
    };

    const leadData = {
      // ...contactDetails,
      fullName: contactDetails.fullName,
      ...(modalType === ModalType.ONLINE_DEGREE && { email: contactDetails.email }),
      isdCode: contactDetails.isdCode,
      phoneNumber: contactDetails.phoneNumber,
      source: utmQuery?.utm_source,
      medium: utmQuery?.utm_medium,
      campaign: utmQuery?.utm_campaign,
      enquiryType: enquiryTypeNPF,
      ...(modalType === ModalType.CATEGORY_PAGE_FORM && { category_name: categoryDetails?.title }),
      ...(modalType === ModalType.CATEGORY_PAGE_FORM && { course_new: selectedDropdownOption }),
      ...(modalType === ModalType.ONLINE_DEGREE && {
        field_student_background: selectedBackground,
      }),
      ...(modalType === ModalType.ONLINE_DEGREE && {
        field_work_experience: selectedWorkExperience,
      }),
      ...(modalType === ModalType.ONLINE_DEGREE && {
        course_new: courseName || (courseDetails && courseDetails?.title),
      }),
      ...(modalType === ModalType.ONLINE_DEGREE && {
        field_course_type: 'online_degree',
        field_college_name:
          collegeName ||
          (courseDetails &&
            courseDetails?.partnerInstituteDetails &&
            courseDetails?.partnerInstituteDetails[0]?.name),
        field_website_page_visit: 'Online Degree', // added this for online degree modal and forms
      }),
    };

    if (!isLoggedIn) {
      try {
        // setCurrentTimerCount(13);
        const response = await LeadCaptureApi.getPPToken(dataToSend);
        if (response) {
          setSaving(true);

          if (leadCaptureType === LeadCaptureType.FORM) {
            setDialogOpen(true);
            setContactDetails({ fullName: '', email: '', isdCode: '+91', phoneNumber: '' });
            setSelectedBackground('');
            setSelectedDropdownOption('');
            setOtp('');
            setSelectedWorkExperience('');
            setIsOtpSent(false);
          }

          setTimeout(() => {
            closeModal();
          }, AUTO_CLOSE_TIME);
          sendLead(leadData);
          if (
            type === LeadType.DOWNLOAD_CURRICULUM ||
            type === LeadType.DOWNLOAD_PLACEMENT_REPORT ||
            type === LeadType.DOWNLOAD_BROCHURE
          ) {
            anchorRef?.current?.click();
          }
          setContactDetails({ fullName: '', email: '', isdCode: '', phoneNumber: '' });
          setTimeout(() => {
            if (window && redirectLink) {
              window.open(redirectLink, '_blank');
            }
          }, 5000);
        }
      } catch (error: any) {
        // console.log(err)
        setIsOtpVerified(false);
      }
    } else {
      // send lead on button click
      if (leadCaptureType === LeadCaptureType.FORM) {
        setDialogOpen(true);
      }
      sendLead(leadData);
      setSaving(true);
      if (
        type === LeadType.DOWNLOAD_CURRICULUM ||
        type === LeadType.DOWNLOAD_PLACEMENT_REPORT ||
        type === LeadType.DOWNLOAD_BROCHURE
      ) {
        anchorRef?.current?.click();
      }
      setCount(AUTO_CLOSE_TIME / 1000);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear any existing timeout
      }
      timeoutRef.current = setTimeout(() => {
        closeModal();
        if (window && redirectLink) {
          setTimeout(() => {
            window.open(redirectLink, '_blank');
          }, 1000);
        }
      }, AUTO_CLOSE_TIME);
    }
  };

  const handleSubmit = async () => {
    // setTimeout(() => {
    //   if (otp === OTP) {
    //     setSaving(true);
    //     setIsOtpVerified(true);
    //     // console.log('truee');
    //     handleDownload();
    //   } else {
    //     setIsOtpVerified(false);
    //     // console.log('faled');
    //   }
    // }, 1000);

    if (
      !isName(contactDetails.fullName) &&
      !isEmail((contactDetails.email && contactDetails?.email) || '') &&
      !isValidIndianPhoneNumber(contactDetails.phoneNumber, contactDetails.isdCode)
    ) {
      // sendLead(data);
      handleDownload();
    } else {
      setIsError({
        ...isError,
        nameError: !!isName(contactDetails.fullName),
        emailError: !!isEmail((contactDetails.email && contactDetails?.email) || ''),
        phoneNumberError: !!isValidIndianPhoneNumber(
          contactDetails.phoneNumber,
          contactDetails.isdCode
        ),
      });
    }
  };

  const renderSubmitButton = () => {
    return (
      <Button type="submit" disabled={disabled}>
        {buttonText}
      </Button>
    );
  };

  const handleDownloadFile = () => {
    return (
      <a
        ref={anchorRef}
        href={curriculumPdf ? `${s3Url}/assets/uploads/curriculum/${curriculumPdf}` : reportLink}
        className="absolute bottom-0 hidden"
        download=""
      >
        &apos;&apos;
      </a>
    );
  };

  const handleDownloadFileLoggedIn = () => {
    const href = curriculumPdf ? `${s3Url}/assets/uploads/curriculum/${curriculumPdf}` : reportLink;

    if (href) {
      const anchor = document.createElement('a');
      anchor.href = href;
      anchor.download = '';
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (contactDetails.phoneNumber) {
      if (currentTimerCount > 0) {
        timer = setInterval(() => {
          setCurrentTimerCount((prev: number) => prev - 1);
        }, 1000);
      }
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [currentTimerCount]);

  useEffect(() => {
    if (downloadDirectly) {
      handleDownloadFileLoggedIn();
      handleDownload();
    }
  }, [downloadDirectly]);

  useEffect(() => {
    if (modalType === ModalType.CATEGORY_PAGE_FORM) {
      getFilteredCourseData(1);
    }
  }, []);

  useEffect(() => {
    if ((dropDownWorkExperience || dropDownBackground) && scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
    }
  }, [dropDownWorkExperience, dropDownBackground]);

  useEffect(() => {
    if (courseDetails?._id) {
      // getIsCourseBought();
      setIsCourseBought(checkIsCourseBought(courseDetails?._id));
    }
  }, [courseDetails]);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (saving && count > 0) {
      timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [saving, count]);

  const updatePhoneNumberFieldInputId = () => {
    const divElement =
      leadCaptureType === LeadCaptureType.MODAL
        ? document.getElementById('phoneNumber')
        : document.getElementById('phoneNumber_input_field');

    // Check if the div element exists
    if (divElement) {
      // Find the input element within the div
      const inputElement = divElement.querySelector('input');

      // Check if input element exists
      if (inputElement) {
        // Update the id attribute
        inputElement.id =
          leadCaptureType === LeadCaptureType.MODAL ? 'phoneNumber' : 'phoneNumber_input_field'; // Replace 'your_custom_id' with your desired id
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      // fields to update id of input field for lead capture
      updatePhoneNumberFieldInputId();
    }, 100);
  }, []);

  return (
    <div>
      {leadCaptureType === LeadCaptureType.FORM && (
        <LeadCaptureForm
          type={type}
          msgLineOne={msgLineOne}
          msgLineTwo={msgLineTwo}
          enquiryType={enquiryType}
          count={count}
          modalType={modalType}
          renderInput={renderInput}
          renderPhoneNumber={renderPhoneNumber}
          renderOtpButton={renderOtpButton}
          renderOtpField={renderOtpField}
          renderEmailField={renderEmailField}
          renderBackgroundDropDown={renderBackgroundDropDown}
          renderWorkExperienceDropDown={renderWorkExperienceDropDown}
          renderSubmitButton={renderSubmitButton}
          renderSelectProgramDropdown={renderSelectProgramDropdown}
          setDialogOpen={setDialogOpen}
          dialogOpen={dialogOpen}
          saving={saving}
          handleSubmit={handleSubmit}
        />
      )}

      {/* for modal */}
      {modelOpen ? (
        <Dialog
          open={modelOpen}
          closeModal={closeModal}
          isOutSideClick
          className={`regular:!w-[90%] ${
            saving ? 'regular:!w-[412px] regular:!h-fit' : 'large:!w-1/2 !p-6 '
          } rounded`}
          titleWrapperClass="border-none !pb-0  !hidden"
        >
          {downloadDirectly || saving ? (
            <Saving type={type} msgLineOne={msgLineOne} msgLineTwo={msgLineTwo} count={count} />
          ) : (
            <LeadCaptureModal
              img={img || ''}
              type={type}
              enquiryType={enquiryType}
              modalType={modalType}
              renderInput={renderInput}
              renderPhoneNumber={renderPhoneNumber}
              renderOtpButton={renderOtpButton}
              renderOtpField={renderOtpField}
              renderEmailField={renderEmailField}
              renderBackgroundDropDown={renderBackgroundDropDown}
              renderWorkExperienceDropDown={renderWorkExperienceDropDown}
              renderSubmitButton={renderSubmitButton}
              closeModal={closeModal}
              isLoggedIn={isLoggedIn}
              scrollableDivRef={scrollableDivRef}
              handleDownloadFile={handleDownloadFile}
              handleSubmit={handleSubmit}
            />
          )}
        </Dialog>
      ) : (
        <div />
      )}
    </div>
  );
};

export default LeadCapture;
