import { ICourseData, ICourseEnquiry, IIsCourseBought } from '@/interfaces/courseDescription';
import { baseApiUrl } from '@/utils/common/env.constants';
import { randomId } from '@pwskills/rachnaui/utils';
import { get, post } from './index';

interface ICourseActivity {
  courseId: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
}

class CourseDescription {
  static async getCourseDescription(slug: string) {
    return get<ICourseData>(
      `/course/${slug}?isSlug=true&random_id=${randomId()}`,
      undefined,
      `${baseApiUrl}/v2`
    );
  }

  static async isCourseBought(courseId: string) {
    return get<IIsCourseBought>(
      `/learn/user/isCourseBought/${courseId}`,
      undefined,
      `${baseApiUrl}/v1`
    );
  }

  static async trackUserCourseActivity(data: ICourseActivity) {
    return post(
      `/course/trackUserCourseActivity/${data?.courseId}`,
      {
        activity: 'explore',
      },
      {
        params: {
          source: data?.utm_source,
          medium: data?.utm_medium,
          campaign: data?.utm_campaign,
        },
      },
      `${baseApiUrl}/v1`
    );
  }

  // static async getUserRatings(courseId?: string) {
  //   if (!courseId) return null;
  //   return get<IUserRating>(`/rating/${courseId}`);
  // }

  // static async addUserRatings(data: { rating: number; courseId?: string }) {
  //   return post<IUserRating>('/rating', data);
  // }

  static async courseEnquiry(
    courseId: string,
    token: string,
    data: {
      fullName: string;
      email?: string;
      isdCode: string;
      phoneNumber: string;
    }
  ) {
    return post<ICourseEnquiry>(
      `course/sendCourseLeadToLeadSquared/${courseId}`,
      {
        ...data,
        'g-recaptcha-response': token,
      },
      undefined,
      `${baseApiUrl}/v1`
    );
  }

  static async courseRedirectionURL(redirectionUrl: string, queryParams?: string) {
    return get<{ data: string }>(
      `/v2/course/redirection-handler?redirectionUrl=${redirectionUrl}&queryParams=${queryParams
        ?.split('&')
        .join(',')}`,
      undefined,
      `${baseApiUrl}`
    );
  }
}

export default CourseDescription;
