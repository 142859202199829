import { NAME_VALIDATOR, EMAIL_PATTERN } from './common.constants';

export const isEmail = (value: string): string | null => {
  if (!value || value?.match(EMAIL_PATTERN)) {
    return null;
  }

  return `Please enter valid email`;
};

export const isName = (value: string): string | null => {
  if (!value || value?.match(NAME_VALIDATOR)) {
    return null;
  }

  return `Please enter valid name`;
};

/**
 * Checks if a phone number is valid 10 digit Indian number based on the provided number & country code.
 *
 * @param phoneNumber
 * @param countryCode
 * @returns a string or null value
 */
export const isValidIndianPhoneNumber = (
  phoneNumber: string,
  countryCode: string
): string | null => {
  if (countryCode === '+91' && phoneNumber && phoneNumber.trim().length < 10) {
    return 'Please enter valid phone number';
  }
  if (countryCode !== '+91' && phoneNumber && phoneNumber.trim().length < 6) {
    return 'Please enter valid phone number';
  }
  return null;
};

export const isMobile = () => {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
};

export const isPassOutYear = (passOutYear: string) => {
  const date = new Date();
  if (+passOutYear < 1980) return 'Please enter correct value';
  if (+passOutYear > date?.getFullYear()) {
    return 'Please enter value less than current year';
  }
  return null;
};

export const isValidSlug = (slug: string) => {
  // Regular expression to check the slug pattern
  const slugPattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  // Test the slug against the pattern
  return slugPattern.test(slug);
};
