import { Typography } from '@pwskills/rachnaui';
import React from 'react';
import Lottie from 'react-lottie';
import { LeadType } from '../../../interfaces/leadCapture.enum';
import thankYouLottie from '../../../assets/Lottie/thank-you-spinning-star.json';

const Saving = ({
  type,
  msgLineOne,
  msgLineTwo,
  count,
}: {
  type: string;
  msgLineOne: string;
  msgLineTwo?: string;
  count: number;
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: thankYouLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="flex flex-col items-center justify-center pb-8">
      <div>
        {(type === LeadType.CONNECT_WITH_COUNSELLOR || type === 'CategoryPageConnect') && (
          <Lottie options={defaultOptions} width={231} height={189} />
        )}
        {(type === LeadType.DOWNLOAD_CURRICULUM ||
          type === LeadType.DOWNLOAD_PLACEMENT_REPORT ||
          type === LeadType.DOWNLOAD_BROCHURE) && (
          <img src="/images/downloadingGif.gif" alt="DownloadingGif" width={256} height={256} />
        )}
        {type === LeadType.ALUMNI_CONNECT && (
          <img
            src="/images/alumni_connect.gif"
            alt="alumniConnectLoadingGif"
            width={256}
            height={256}
          />
        )}
      </div>
      <Typography variant="bold" component="body-small" className="text-gray-850">
        {msgLineOne}
      </Typography>
      <Typography
        variant="bold"
        component={type === LeadType.ALUMNI_CONNECT ? 'body-small' : 'body-tiny'}
        className={type === LeadType.ALUMNI_CONNECT ? 'text-gray-850' : 'text-gray-650'}
      >
        {msgLineTwo}
      </Typography>
      <Typography variant="bold" component="body-tiny" className="text-gray-650 mt-2">
        Closing in {count}
      </Typography>
    </div>
  );
};

export default Saving;
