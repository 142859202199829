/* eslint-disable @typescript-eslint/indent */
import { IconButton, Typography } from '@pwskills/rachnaui';
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import CloseModalIcon from '@/assets/icons/CloseModalIcon.svg';
import { LeadType, ModalType } from '../../../interfaces/leadCapture.enum';
import { LeadCaptureModalProps } from '../../../interfaces/leadCapture';

const LeadCaptureModal = (props: LeadCaptureModalProps) => {
  const {
    type,
    img,
    enquiryType,
    modalType,
    renderInput,
    renderPhoneNumber,
    renderOtpButton,
    renderOtpField,
    renderEmailField,
    renderBackgroundDropDown,
    renderWorkExperienceDropDown,
    renderSubmitButton,
    closeModal,
    isLoggedIn,
    scrollableDivRef,
    handleDownloadFile,
    handleSubmit,
  } = props;

  const isOnlineDegree = modalType === ModalType.ONLINE_DEGREE;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-x-2 justify-between relative !w-full">
        <Typography
          component="subheading"
          variant="bold"
          className="!text-[18px] regular:!text-[20px]"
        >
          {isOnlineDegree
            ? 'Start Your Journey With Us'
            : type === LeadType.CONNECT_WITH_COUNSELLOR
            ? 'Get In Touch'
            : type === LeadType.ALUMNI_CONNECT
            ? 'Start Your Journey Here'
            : 'Enter Your Details'}
        </Typography>
        <IconButton
          onClick={() => {
            closeModal();
          }}
        >
          <Image src={CloseModalIcon} alt="CloseModalIcon" className="!w-6 !h-6" />
        </IconButton>
      </div>

      <div className="w-full h-[360px] flex">
        <form
          id={`${enquiryType}_form`}
          className={` w-full regular:pr-6 flex flex-col justify-between ${
            isOnlineDegree ? '' : 'gap-12'
          } w-full regular:!w-1/2`}
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <div
            ref={scrollableDivRef}
            className={`overflow-y-scroll ${isOnlineDegree && 'lead-form-scroll'}`}
          >
            {renderInput()}

            <div className="flex items-end">
              {renderPhoneNumber()}
              {!isLoggedIn && renderOtpButton()}
            </div>

            {!isLoggedIn && renderOtpField()}

            {isOnlineDegree && (
              <>
                {renderEmailField()}
                {renderBackgroundDropDown()}
                {renderWorkExperienceDropDown()}
              </>
            )}
          </div>

          {isOnlineDegree && (
            <div className="text-left medium:!text-center large:!text-left mb-3 mt-4">
              <Typography variant="semi-bold" component="body-tiny" className="!text-gray-850">
                By submitting this form you accept and agree to our{' '}
                <Link href="/terms-and-conditions" target="_blank">
                  <span className="semi-bold text-[#E97862]">terms of use</span>
                </Link>
              </Typography>
            </div>
          )}

          {renderSubmitButton()}
          {handleDownloadFile && handleDownloadFile()}
        </form>
        <div
          className={`hidden absolute right-0 top-0 z-[-1] h-full regular:!flex justify-center items-end ${
            isOnlineDegree ? 'bg-black' : 'bg-blue-50'
          }  rounded-tr rounded-br w-1/2`}
        >
          <img
            src={img}
            alt="getInTOuch"
            width={369}
            style={{ height: '100%', width: '100%' }}
            className="object-cover rounded-tr rounded-br "
          />
        </div>
      </div>
    </div>
  );
};

export default LeadCaptureModal;
